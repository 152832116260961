import React, { useEffect, useState } from "react"
import NuvoImage from "./NuvoImage"
import "./LocationPopup.scss"

const LocationPopup = () => {
  const [slide, setSlide] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setSlide(true)
    }, 1000)
  }, [])

  if (!isOpen) return null
  return (
    <div className={`pu-card ${slide && "pu-slide"}`}>
      <div className="pu-card-white-bkg">
        <button
          onClick={() => setIsOpen(false)}
          aria-label="close popup"
          className="reset pu-card-close">
          ✕
        </button>
        <p className="pu-card-heading">
          Coming Soon <br />
          to South San Diego
        </p>
        <NuvoImage publicId={"SDOS/DEV/sdos-new-location-popup"} />
        <p className="pu-card-text">New Location. <br/>Same Professional Care.</p>
      </div>
    </div>
  )
}

export default LocationPopup
